import * as React from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { CDN_BFLDR_URL } from 'src/constants';
import { UitkPrimaryButton } from 'uitk-react-button';
import { UitkCard } from 'uitk-react-cards';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLink } from 'uitk-react-link';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkView } from 'uitk-react-view';

const Landing: React.FC<RouteComponentProps> = () => (
  <main role="main" className="page-container" style={{ width: '100vw', height: '100vh', background: 'white' }}>
    <UitkView className="page-container">
      <UitkLayoutFlex alignItems="center" justifyContent="center">
        <UitkLayoutFlexItem alignSelf="center">
          <img
            src={`${CDN_BFLDR_URL}EG_WordmarkLogo_Blue-on-Transparent_04-27-2023.ai?auto=webp&format=svg`}
            alt="Descriptive Text"
            style={{ width: '220.95px', height: 'auto' }}
          />
        </UitkLayoutFlexItem>
      </UitkLayoutFlex>
      <UitkLayoutFlex alignItems="center" justifyContent="center">
        <UitkLayoutFlexItem alignSelf="center">
          <UitkSpacing>
            <UitkCard padded>
              <UitkHeading tag="h4" size={4}>
                Sign in to Expedia Group Ads Portal
              </UitkHeading>
              <UitkSpacing margin={{ block: 'six' }}>
                <UitkLayoutFlex alignItems="center" justifyContent="center">
                  <UitkLayoutFlexItem>
                    <UitkPrimaryButton style={{ width: '100%' }} onClick={() => (window.location.href = '/home')}>
                      Continue
                    </UitkPrimaryButton>
                  </UitkLayoutFlexItem>
                </UitkLayoutFlex>
              </UitkSpacing>
              <UitkLayoutFlex alignItems="center" justifyContent="center">
                <UitkLayoutFlexItem alignSelf="center" style={{ fontSize: '11px' }}>
                  <UitkText>By continuing, you have read and agree to the following:</UitkText>
                </UitkLayoutFlexItem>
              </UitkLayoutFlex>
              <UitkLayoutFlex alignItems="center" justifyContent="center">
                <UitkLayoutFlexItem alignSelf="center">
                  <UitkLink>
                    <a
                      href="https://advertising.expedia.com/privacy-statement/"
                      style={{ justifyContent: 'center', fontSize: '11px', color: 'blue' }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Statement
                    </a>
                  </UitkLink>
                </UitkLayoutFlexItem>
              </UitkLayoutFlex>
            </UitkCard>
          </UitkSpacing>
        </UitkLayoutFlexItem>
      </UitkLayoutFlex>
    </UitkView>
  </main>
);

Landing.displayName = 'Landing';

export default Landing;
